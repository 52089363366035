import React, { useEffect, useState } from "react"
import loadable from "@loadable/component"
import { GLOBAL_APPLICATION_EXTERNAL_LINKS } from "../../links/external"

const Layout = loadable(() => import("@common/Layout/Layout"))
const Careers = loadable(() => import("@components/Careers/Careers"))
const SEO = loadable(() => import("@components/Common/SEO/SEO"))

const CareersPage = ({ path }) => {
  const [vacancies, setVacancies] = useState([])
  const [seoData, setSeoData] = useState([])

  const fetchData = async (url, { headers = {}, onSuccess }) => {
    try {
      const response = await fetch(url, { headers })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const data = await response.json()
      onSuccess(data)
    } catch (error) {
      console.error(`Fetch failed for ${url}:`, error)
    }
  }

  useEffect(() => {
    fetchData(
      `${GLOBAL_APPLICATION_EXTERNAL_LINKS.STRAPI_URL}/seo-descriptions`,
      {
        onSuccess: data => {
          setSeoData(data)
        },
      }
    )

    fetchData(
      `${GLOBAL_APPLICATION_EXTERNAL_LINKS.PEOPLE_FORCE_URL}/vacancies?status[]=opened`,
      {
        headers: {
          "X-API-KEY": process.env.GATSBY_PEOPLE_FORCE_TOKEN,
        },
        onSuccess: data => {
          if (data?.data) {
            setVacancies(data.data.filter(v => v.post_on_careers))
          }
        },
      }
    )
  }, [])

  return (
    <>
      <SEO
        title="Join Our Team and Build Your Careers with Us!"
        titleTemplate="Impressit"
        seoData={seoData}
        description="Want to become a part of an exceptional team? And we are always ready to hire and to add some great professionals to the Impressit team. Send us your CV!"
        path={path}
      />
      <Layout path={path}>
        <Careers data={vacancies} />
      </Layout>
    </>
  )
}

export default CareersPage
